import Book from "@/svg/book"
import Home from "@/svg/home"
import Library from "@/svg/library"
import Wishlist from "@/svg/wishlist"
import { Box, Stack, VStack, Text } from "@chakra-ui/react"
import { Translation } from "next-i18next"
import NavigationButton from "./NavigationButton"
import UserAvatarButton from "../UserAvatar/UserAvatarButton"

export const menuItems = [
  {
    title: <Translation>{(t) => t("menu.groups")}</Translation>,
    icon: Home,
    link: "/",
    content: "Groupes",
  },
  {
    title: <Translation>{(t) => t("menu.library")}</Translation>,
    icon: Library,
    link: "/reviews",
    content: "Biblio",
  },
  {
    title: <Translation>{(t) => t("menu.add")}</Translation>,
    icon: Book,
    link: "/reviews/create",
    content: "Review",
  },
  {
    title: <Translation>{(t) => t("menu.wishlist")}</Translation>,
    icon: Wishlist,
    link: "/wishlist",
    content: "Wishlist",
  },
]

const NavigationBar = ({ isMobile }: { isMobile?: boolean }) => {
  const items = menuItems.map((item, idx) => (
    <NavigationButton
      key={idx}
      {...item}
      type="icon"
      tooltip
      isMobile={isMobile}
    />
  ))

  if (isMobile) {
    return (
      <Box
        position="sticky"
        bottom={0}
        width="100%"
        bg="white"
        zIndex={10}
        padding={0}
      >
        <Stack
          flexDir="row"
          justifyContent="space-between"
          alignItems="baseline"
          spacing={0}
          maxWidth="400px"
          margin="0 auto"
          padding={2}
          paddingTop={3}
        >
          {items}

          <VStack alignSelf="start">
            <UserAvatarButton tooltip height="25px" width="25px" isMobile />
            <Text fontFamily="GTMaru" style={{ fontSize: "0.7rem" }}>
              Profil
            </Text>
          </VStack>
        </Stack>
      </Box>
    )
  }

  return (
    <Box borderRight="2px solid black" height="100%" paddingTop={10}>
      <Stack spacing={8} padding={2} margin="0 auto" alignItems="center">
        <UserAvatarButton tooltip />
        {items}
      </Stack>
    </Box>
  )
}

export default NavigationBar
